import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class LogService extends IService {
    constructor(http) {
        super(http);
    }

    async list(limit = '', skip = '', filter = '') {
        try {
            let response = await this.http.get(`${server_base}/api/logs?limit=${limit}&skip=${skip}&${filter}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async listimportexport(limit = '', skip = '', filter = '') {
        try {
            let response = await this.http.get(`${server_base}/api/import_export?limit=${limit}&skip=${skip}&${filter}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async listExportImportInventory(limit = '', skip = '', filter = '') {
        try {
            let response = await this.http.get(`${server_base}/api/import_export/export-inventory?limit=${limit}&skip=${skip}&${filter}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default LogService;
