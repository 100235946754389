import {required, minLength, maxLength, numeric,} from 'vuelidate/lib/validators'

export default {
  schedule: {
    fileName: {
      required,
      minLength: minLength(2)
    },
    name: {
      required,
      minLength: minLength(4)
    },
    protocol: {
      required,
    },
    host: {
      required,
    },
    port: {
      required,
      numeric
    },
    user: {
      required,
    },
    description: {
      maxLength: maxLength(200)
    },
    password: {
      required
    }
  },
}