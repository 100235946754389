import LeadService from '@/services/lead.service';
import CompanyService from "@/services/company.service";
import Draggable from 'vuedraggable';

import {mapActions} from 'vuex';
const leadService = new LeadService();

export default {

    name: 'export-leads',

    components: {Draggable},

    props:[],

    data() {
        return {
            generalList: [],
            exportList: [],
            headers: {},
            feedType: 'Default',
            checkAll: false,
            company: null,
            companies: [],
            loading: false,
            extension: 'csv',
            extensions: [{_id: 'csv', Name: 'CSV'},{_id: 'json', Name: 'JSON'}]
        }
    },
    watch: {
      async company() {
          await this.loadLeads();
      }
    },
    computed: {
        getTextCheckButton() {
            return !this.checkAll ? 'Check All' : 'Uncheck All';
        },
        isNoData() {
            return this.generalList.length === 0;
        }
    },
    async mounted() {
        if(this.$getUser().permissions.isAdmin) {
            let Company = JSON.parse(localStorage.getItem('user')).Company
            if(Company !== undefined)
                this.company = Company._id;
            await this.loadLeads();
        }
    },
    methods: {
        ...mapActions([
            'setDownloading'
        ]),

        async Export() {
            this.loading = true;  
            this.setDownloading(true);          
            const res = await leadService.export(this.exportList, this.$getUser().Company._id, this.extension);            
            if(res && !res.error) {
                const byte = this.base64ToArrayBuffer(res.data.array)
                let blob = new Blob([byte], {type: "application/octet-binary;charset=utf-8"});                
               
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', `${res.data.name}.${this.extension}`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            this.setDownloading(false);          
            this.loading = false;
        },  

        async loadLeads() {
            this.generalList = [];
            this.exportList = [];
            const res = await leadService.list(1, 0, '', '', null, this.company);
            if (res && !res.error) {                
                if(res.data.leads.length > 0) {
                    this.list = res.data.leads.map(lead => {
                        delete lead._id;
                        delete lead.__v;
                        delete lead.Agent;
                        delete lead.Assign;
                        delete lead.Car;
                        delete lead.Campaign;
                        delete lead.Company;
                        delete lead.Customer;
                        delete lead.FacebookFormId;
                        delete lead.FacebookFormName;
                        delete lead.FireStatus;
                        delete lead.Lost;
                        delete lead.SID;
                        delete lead.Stage;
                        delete lead.Tags;
                        delete lead.Won;
                        delete lead.removed;
                        delete lead.updatedAt;
                        return lead
                    });

                    for (let key of this.getFileHeaders()) {
                        this.generalList.push({key: key, value: key, selected: false});
                    }
                }
            }
        },

        async loadCompanies() {
            const service = new CompanyService();
            let res = await service.allActive();
            if(res && !res.error) {
                this.companies = res.data.companies;
            }
        },

        onCheckHeader(node) {
            if (node.selected === true)
                this.exportList.push(node);
            else {                
                const index = this.exportList.findIndex(el => el.key === node.key);
                if (index !== -1)
                    this.exportList.splice(index, 1);
            }
        },

        onCheckAllClick() {
            this.checkAll = !this.checkAll;
            for (let i = 0; i < this.generalList.length; i++) {
                this.generalList[i].selected = this.checkAll;
            }
            if (this.checkAll) 
                this.exportList = Object.assign([], this.generalList);
            else
                this.exportList = [];
        },

        onNextClick() {
            if (this.exportList.length === 0)
                this.$notify({
                    type: 'error',
                    message: 'You have not fields selected.'
                });
            else {
                const data = this.exportList;
                this.$emit('ExportEvent', data);
            }

        },

        onClick(ele) {            
            if(ele.selected) {
                let index = this.exportList.findIndex(el => el.value === ele.value);                
                if(index !== -1) {
                    this.exportList.splice(index, 1);
                    ele.selected = false;
                    this.checkAll = false;
                }                
            } else {                
                this.exportList.push({label: ele.value, value:ele.value});
                ele.selected = true;
            }
        },

        getFileHeaders() {
            return Object.keys(this.list[0]);//.slice(0,5)
        },

        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }

    }
}
