import CompanyService from '@/services/company.service.js';
import InventoryService from '@/services/inventory.service.js';
import LeadsService from '@/services/lead.service';

import List from '../List/index.vue';
import SetData from '../SetData/index.vue';
import FtpFeedJob from '../FtpFeedJobs/index.vue';

const companyService = new CompanyService();
const inventoryService = new InventoryService();
const service = new LeadsService();

export default {

    name: 'dataExport',

    components:{
        List,
        SetData,
        FtpFeedJob
    },

    props: [],

    data() {
        return {
            windows: {
                selectCompany: true,
                previewData: false,
                createdModal: false,
                selectExtension: false,
                scheduleModal: false,
            },
            images: {
                frame: false,
                zoom: false
            },
            extension: 'csv',
            companyList: [],
            company: null,
            tab:null,
            showError: '',
            exportType: null,
        }
    },

    computed: {
        isFeedSection(){
          return this.company && (this.tab === 'lead' || this.tab === 'feed');
        },
        isFtpJobSection(){
            return this.company && this.tab === 'schedule';
        },
    },

    async mounted() {
        let Company = JSON.parse(localStorage.getItem('user')).Company
        if(Company !== undefined)
            this.company = Company._id;
    },

    methods: {

        async onExportFeedClick(company) {
            this.company = company;
            const res = await companyService.get(this.company);
            if (res && !res.error) {
                const config = res.data.CompanySettings.Feed.FtpUpload;
                this.schedule.user = config.user;
                this.schedule.password = config.password;
                this.schedule.port = config.port;
                this.schedule.host = config.host;
                this.schedule.name = !config.name || config.name === '' ? `${res.data.Name}-Feed` : config.name;
                this.headers = config.headers;
            }
            this.windows.scheduleModal = true;
        },

        cancelExportModal() {
            this.windows.createdModal = false;
        },

        setTab(action){
            this.tab = action;
            this.exportType = action === 'lead' ? action : 'car';
        },

        async onFeedClick(company) {

            this.company = company;
            this.exportType = 'car';
            this.windows.previewData = true;
            this.windows.selectCompany = false;
        },

        async onLeadClick(company){
            this.company = company;
            this.exportType = 'lead';
            this.windows.previewData = true;
            this.windows.selectCompany = false;
        },

        showExportModal(headers){
            this.headers = headers;
            this.windows.createdModal = true;
        },

        home() {
            this.tab = null;

        },

        convertHeadersInFieldsParser() {
            let array = [];
            for (let item of  this.headers) {
                array.push({label: item.value, value: item.key})
            }
            return array;
        },

        async Export() {

            if(typeof this.headers == 'string'){
                this.BuildFeed(this.headers);
                return;
            }
            const fields = this.convertHeadersInFieldsParser();
            let res = this.exportType === 'car' ?
                await inventoryService.Export(fields, this.company, this.extension, this.images) :
                await service.export(fields, this.company, this.extension);

            if (res && !res.error) {
                const byte = this.base64ToArrayBuffer(res.data.array)
                let blob = new Blob([byte], {type: "application/octet-binary;charset=utf-8"});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', `${res.data.name}.${this.extension}`);
                document.body.appendChild(link);
                link.click();
            }

        },

        async BuildFeed(type){

            let res = null
            switch (type) {
                case "Facebook":
                    res = await inventoryService.GetFacebookFeed(this.company);
                    break;
                case "FacebookShop":
                    res = await inventoryService.GetFacebookShopFeed(this.company);
                    break;
                case "Adwords":
                    res = await inventoryService.GetAdWordsFeed(this.company);
                    break;
            }

            if (res && !res.error) {
                const byte = this.base64ToArrayBuffer(res.data.array)
                let blob = new Blob([byte], {type: "application/octet-binary;charset=utf-8"});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', `${res.data.name}.csv`);
                document.body.appendChild(link);
                link.click();
            }

        },


        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes;
        }

    },

}
