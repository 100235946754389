import InventoryService from '@/services/inventory.service.js';
import LeadService from '@/services/lead.service';
import Draggable from 'vuedraggable';


const inventoryService = new InventoryService();
const leadService = new LeadService();

export default {

  name: 'set-data',

  components: {Draggable},

  props: ['company', 'exportType'],

  data () {
    return {
      generalList:[],
      exportList:[],
      feedType: 'Default',
      headers:{},
      checkAll: false,
    }
  },
  computed: {
    getTextCheckButton(){
      return !this.checkAll ? 'Check All' : 'Uncheck All';
    },
    isNoData(){
      return this.generalList.length === 0;
    }
  },
  mounted () {
    if(this.exportType === 'car')
      this.loadPreviewCars();
    else
      this.loadLeads();
  },
  methods: {

    async loadPreviewCars(){
      let res = await inventoryService.getTenCars(this.company);
      if (res && !res.error) {
        this.list = res.data;

        for (let key of this.getFileHeaders()) {
          this.generalList.push({key: key, value: key, selected: false});
        }
      }
    },

    async loadLeads(){
        this.generalList = [];
        this.exportList = [];
        const res = await leadService.list(1, 0, '', '', null, this.company);
        if (res && !res.error) {

          this.list = res.data.leads.map(lead => {
            delete lead._id;
            delete lead.__v;
            delete lead.Agent;
            delete lead.Assign;
            delete lead.Car;
            delete lead.Campaign;
            delete lead.Company;
            delete lead.Customer;
            delete lead.FacebookFormId;
            delete lead.FacebookFormName;
            delete lead.FireStatus;
            delete lead.Lost;
            delete lead.SID;
            delete lead.Stage;
            delete lead.Tags;
            delete lead.Won;
            delete lead.removed;
            delete lead.updatedAt;
            return lead
          });

          for (let key of this.getFileHeaders()) {
            this.generalList.push({key: key, value: key, selected: false});
          }
        }
    },

    onCheckHeader(node){
      if(node.selected === true)
        this.exportList.push(node);
      else{
        const index = this.exportList.findIndex(el => el.key === node.key);
        if(index !== -1)
          this.exportList.splice(index,1);
      }
    },

    onCheckAllClick(){
      this.checkAll = !this.checkAll;
      for(let i=0; i< this.generalList.length; i++){
        this.generalList[i].selected= this.checkAll;
      }
      if(this.checkAll)
        this.exportList = Object.assign([],this.generalList);
      else
        this.exportList =[];
    },

    onNextClick(){
      if(this.exportList.length === 0 && this.feedType === 'Default')
        this.$notify({
          type: 'error',
          message: 'You have not fields selected.'
        });
      else{
        const data = this.exportType === 'car' && this.feedType !== 'Default' ? this.feedType : this.exportList;
        this.$emit('ExportEvent', data);
      }

    },

    getFileHeaders() {
      return Object.keys(this.list[0]);//.slice(0,5)
    },
    onClose(){
      this.$emit('close')
    },

  }
}
