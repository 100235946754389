export default {
  fileNameErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.fileName.required) return 'required_field';
    if (!this.$v.schedule.fileName.minLength) return this.$t('min_length');
    return null;
  },
  nameErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.name.required) return 'required_field';
    if (!this.$v.schedule.name.minLength) return this.$t('min_length');
    return null;
  },
  protocolErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.protocol.required) return 'required_field';
    return null;
  },
  hostErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.host.required) return 'required_field';
    return null;
  },
  portErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.port.required) return 'required_field';
    if (!this.$v.schedule.port.numeric) return this.$t('port_number');
    return null;
  },
  userErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.user.required) return 'required_field';
    return null;
  },
  passwordErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.password.required) return 'required_field';
    return null;
  },
  descriptionErrors() {
    if (!this.$v.$anyError) return null;
    if (!this.$v.schedule.description.maxLength) return this.$t('max_length');
    return null;
  }
}