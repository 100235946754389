import LeadSourceService from '@/services/lead.source.service';

const service = new LeadSourceService();

export default {
  name: 'SourceFilter',

  props: {
    selected: {
      type: Object | String,
      default: null
    },
  },

  data() {
    return {
      sources: [],
      source: null,
      search: ''
    }
  },

  created() {
    this.initializeSourceFromQuery();
  },

  async mounted() {
    await this.list();

    if (this.$route.query.source) {
      this.setSourceFromQuery();

    } else if (this.selected) {
      await this.setSelectedSource();
    }
  },

  computed: {
    sourcess() {
      if (this.search) {
        return this.sources.filter(source => {
          return this.search
              .toLowerCase()
              .split(' ')
              .every(search => {
                return source.Name.toLowerCase().includes(search);
              });
        });
      }

      return this.sources
    }
  },

  methods: {
    async toggleSource(source) {
      await this.setRouteQuery(source);

      this.source = source;
      this.$emit('select', source);
      this.$hideModals();
      this.search = '';
    },

    async list() {
      const res = await service.list(500);
      if (res && !res.error) {
        this.sources = res.data.sources;
      }
    },

    async setSelectedSource() {
      if (this.selected) {
        if (typeof this.selected === 'string') {
          const index = this.sources.findIndex(source => source.Name === this.selected);
          if (index > -1) {
            this.source = this.sources[index];
          }

        } else {
          const index = this.sources.findIndex(source => source._id === this.selected._id);
          if (index > -1) {
            this.source = this.sources[index];
          }
        }
      }
    },

    setSourceFromQuery() {
      const querySource = this.$route.query.source;

      if (querySource) {
        const index = this.sources.findIndex(source => source.Name === querySource);
        if (index > -1) {
          this.source = this.sources[index];
        }
      }
    },

    async setRouteQuery(source) {
      if (source) {
        const isValidSourceQuery = source?._id !== this.source?._id;
        if (isValidSourceQuery) {
          await this.$router.replace({
            path: this.$route.path,
            query: {...this.$route.query, source: source?.Name || source},
          });
        }

      } else {
        const currentQuery = {...this.$route.query};
        if (currentQuery.source) {
          delete currentQuery.source;
          await this.$router.replace({path: this.$route.path, query: {...currentQuery}});
        }
      }
    },

    initializeSourceFromQuery() {
      const sourceFromQuery = this.$route.query.source;

      if (sourceFromQuery) {
        this.$emit('init', sourceFromQuery);
      }
    }
  },
}