import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].program_server;
const inventory_base = config[config.stage].inventory_server;

class FeedJobService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async create(feedJob) {
    try {
      const response = await this.http.post(`${server_base}/api/feed-job`, feedJob);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async get(id) {
    try {
      const response = await this.http.get(`${server_base}/api/feed-job/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(id) {
    try {
      const response = await this.http.delete(`${server_base}/api/feed-job/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip, filters = {}) {
    try {
      const query = {...filters};

      if (limit) query.limit = limit;
      if (skip) query.skip = skip;

      this.addLoading('list_feed_jobs');
      const response = await this.http.get(`${server_base}/api/feed-job`, query);
      this.addLoading('list_feed_jobs');
      return response.data;
    } catch (e) {
      this.addLoading('list_feed_jobs');
      return null;
    }
  }

  async launch(id) {
    try {
      let response = await this.http.get(`${inventory_base}/api/feed/schedule/start/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async uploadToServer(id) {
    try {
      let response = await this.http.post(`${inventory_base}/api/feed/upload/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default FeedJobService;