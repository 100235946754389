import Dh from "../../../helpers/date.helper";

export default {
  name: 'Timeframe',

  props: {
    selected: String,
    items: {
      type: Array, default() {
        return ['custom', 'today', 'yesterday', 'this_week', 'last_7_days', 'last_week', 'last_14_days', 'this_month', 'last_30_days', 'last_month', 'last_90_days', 'this_year', 'last_year', 'all_time']
      }
    },
  },

  data() {
    return {
      timeframe: this.selected || 'all_time',
      timeframes: this.items,

      dateRangeFrom: '',
      dateRangeTo: '',

      dateRange: {
        from: '',
        to: ''
      }
    }
  },

  created() {
    this.initializeDateRangeFromQuery();
  },

  async mounted() {
    if (this.$route.query.dateRange) {
      this.setDateRangeFromQuery();

    } else if (this.selected) {
      await this.setSelectedDateRange();
    }
  },

  methods: {
    async toggleTimeframe(timeframe) {
      this.timeframe = timeframe;
      this.dateRange = this._parseDateRange(timeframe);

      if (timeframe !== 'custom') {
        await this.applyTimeframe();
      }
    },

    async applyTimeframe() {
      this.$emit('select', Object.assign(this.dateRange, {timeframe: this.timeframe}));
      await this.setRouteQuery(this.timeframe);
      this.$hideModals();
    },

    async applyCustomTimeframe() {
      this.dateRange.from = Dh.instance(this.dateRangeFrom).format()
      this.dateRange.to = Dh.instance(this.dateRangeTo).format()

      this.$emit('select', Object.assign(this.dateRange, {timeframe: this.timeframe}));
      await this.setRouteQuery('custom');
      this.$hideModals();
    },

    async setSelectedDateRange() {
      const isSelected = !(!this.selected.length || this.selected === 'all_time');

      if (isSelected) {
        this.dateRange = this._parseDateRange(this.selected);
      }
    },

    setDateRangeFromQuery() {
      const dateRange = JSON.parse(this.$route.query.dateRange || '{}');
      const dateRangeQuery = !(!dateRange?.timeframe || dateRange?.timeframe === 'all_time');

      if (dateRangeQuery) {
        this.timeframe = dateRange.timeframe;
        this.dateRange = {
          from: dateRange.from,
          to: dateRange.to,
        }
      }
    },

    async setRouteQuery(timeframe) {
      const queryDateRange = JSON.parse(this.$route.query.dateRange || '{}');
      const noTimeframe = !timeframe?.length || timeframe === 'all_time';

      if (noTimeframe) {

        const currentQuery = {...this.$route.query};
        if (currentQuery.dateRange) {
          delete currentQuery.dateRange;
          await this.$router.replace({path: this.$route.path, query: {...currentQuery}});
        }

      } else if (timeframe === 'custom') {

        const dateRange = {
          ...this.$route.query, dateRange: JSON.stringify({
            timeframe: 'custom',
            from: this.dateRangeFrom,
            to: this.dateRangeTo,
          })
        }

        await this.$router.replace({path: this.$route.path, query: {...dateRange}});

      } else {

        const isValidTimeframeQuery = timeframe !== queryDateRange?.timeframe;
        if (isValidTimeframeQuery) {
          await this.$router.replace({
            path: this.$route.path,
            query: {...this.$route.query, dateRange: JSON.stringify({timeframe})}
          });
        }

      }
    },

    initializeDateRangeFromQuery() {
      const dateRangeFromQuery = JSON.parse(this.$route.query.dateRange || '{}'),
          timeFrameFromQuery = dateRangeFromQuery.timeframe && dateRangeFromQuery.timeframe !== 'all_time';

      if (timeFrameFromQuery) {
        const dateRange = this._parseDateRange(dateRangeFromQuery.timeframe);

        this.$emit('init', {
          timeframe: dateRangeFromQuery.timeframe,
          from: dateRange.from,
          to: dateRange.to,
        });

      } else if (this.selected) {
        const dateRange = this._parseDateRange(this.selected);

        this.$emit('init', {
          timeframe: this.selected,
          from: dateRange.from,
          to: dateRange.to,
        });
      }
    },

    _parseDateRange(timeframe) {
      if (timeframe === 'custom') {
        const dateRange = JSON.parse(this.$route.query.dateRange || '{}');
        this.dateRangeFrom = dateRange.from || Dh.customFormat(Dh.subtract(new Date(), 7, 'day'));
        this.dateRangeTo = dateRange.to || Dh.customFormat();
      }

      const defaultDateRange = {
        from: '',
        to: '',
      }

      const dateRanges = {
        today: {
          from: Dh.instance(new Date()).startOf('day').format(),
          to: Dh.instance(new Date()).endOf('day').format(),
        },
        yesterday: {
          from: Dh.subtract(new Date(), 1, 'day').startOf('day').format(),
          to: Dh.subtract(new Date(), 1, 'day').endOf('day').format()
        },
        this_week: {
          from: Dh.startOf(new Date(), 'isoWeek').format(),
          to: Dh.endOf(new Date(), 'isoWeek').format()
        },
        last_7_days: {
          from: Dh.subtract(new Date(), 7, 'day').startOf('day').format(),
          to: Dh.instance(new Date()).endOf('day').format()
        },
        last_week: {
          from: Dh.subtract(new Date(), 1, 'week').startOf('isoWeek').format(),
          to: Dh.subtract(new Date(), 1, 'week').endOf('isoWeek').format()
        },
        last_14_days: {
          from: Dh.subtract(new Date(), 14, 'day').startOf('day').format(),
          to: Dh.instance(new Date()).endOf('day').format()
        },
        this_month: {
          from: Dh.startOf(new Date(), 'month').format(),
          to: Dh.endOf(new Date(), 'month').format()
        },
        last_30_days: {
          from: Dh.subtract(new Date(), 30, 'day').startOf('day').format(),
          to: Dh.instance(new Date()).endOf('day').format()
        },
        last_month: {
          from: Dh.subtract(new Date(), 1, 'month').startOf('month').format(),
          to: Dh.subtract(new Date(), 1, 'month').endOf('month').format()
        },
        last_90_days: {
          from: Dh.subtract(new Date(), 90, 'day').startOf('day').format(),
          to: Dh.instance(new Date()).endOf('day').format()
        },
        this_year: {
          from: Dh.startOf(new Date(), 'year').format(),
          to: Dh.endOf(new Date(), 'year').format()
        },
        last_year: {
          from: Dh.subtract(new Date(), 1, 'year').startOf('year').format(),
          to: Dh.subtract(new Date(), 1, 'year').endOf('year').format()
        },
        custom: {
          from: Dh.instance(this.dateRangeFrom).startOf('day').format(),
          to: Dh.instance(this.dateRangeTo).endOf('day').format()
        },
      }

      return dateRanges[timeframe] || defaultDateRange;
    },
  },

  filters: {
    customDate(date) {
      if (!date) return;
      return Dh.customFormat(date, 'MM/DD/YYYY');
    }
  }
}