export default {
  name: 'CustomFilter',

  props: {
    label: {
      type: String,
      default: 'all'
    },
    selected: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      item: '',
      search: ''
    }
  },

  computed: {
    itemss() {
      if (this.search) {
        const lowerCaseSearch = this.search.toLowerCase();

        return this.items.filter(item => {
          const lowerCaseItem = (item.text || item).toLowerCase();
          return lowerCaseSearch.split(' ').every(lCSearch =>
              lowerCaseItem.includes(lCSearch)
          );
        });
      }

      return this.items;
    }
  },

  mounted() {
    this.setSelected()
  },

  methods: {
    toggleItem(item) {
      this.item = item;
      this.$emit('select', item);
      this.$hideModals();
      this.search = '';
    },

    setSelected() {
      if (this.selected && this.selected.length) {
        const index = this.items.findIndex(item => item.value || item === this.selected);

        if (index > -1) {
          this.item = this.items[index];
        }
      }
    },
  },
}