import Draggable from 'vuedraggable';
import {VueTabs, VTab} from 'vue-nav-tabs';

import Dh from "@/helpers/date.helper";

import 'vue-nav-tabs/themes/vue-tabs.css';
import ChosenMultiple from '@/components/UI/Choosen';

import Validations from './Validations';
import Errors from './Errors';

import Sources from "@/components/Filters/Source/Source";
import Agents from "@/components/Filters/Agent/Agent";
import CustomFilter from "@/components/Filters/Custom/Custom";
import Timeframe from "@/components/Filters/Timeframe/Timeframe";

import FeedJobService from '@/services/feed.job.service';
import InventoryService from '@/services/inventory.service';

const feedJobService = new FeedJobService();
const inventoryService = new InventoryService();

const times = [
  {text: '12:00 AM', value: 0},
  {text: '1:00 AM', value: 1},
  {text: '2:00 AM', value: 2},
  {text: '3:00 AM', value: 3},
  {text: '4:00 AM', value: 4},
  {text: '5:00 AM', value: 5},
  {text: '6:00 AM', value: 6},
  {text: '7:00 AM', value: 7},
  {text: '8:00 AM', value: 8},
  {text: '9:00 AM', value: 9},
  {text: '10:00 AM', value: 10},
  {text: '11:00 AM', value: 11},
  {text: '12:00 PM', value: 12},
  {text: '1:00 PM', value: 13},
  {text: '2:00 PM', value: 14},
  {text: '3:00 PM', value: 15},
  {text: '4:00 PM', value: 16},
  {text: '5:00 PM', value: 17},
  {text: '6:00 PM', value: 18},
  {text: '7:00 PM', value: 19},
  {text: '8:00 PM', value: 20},
  {text: '9:00 PM', value: 21},
  {text: '10:00 PM', value: 22},
  {text: '11:00 PM', value: 23},
]

export default {
  name: 'ftp-feed-jobs',

  components: {
    Timeframe, CustomFilter, Agents, Sources,
    VueTabs,
    VTab,
    Draggable,
    ChosenMultiple
  },

  data() {
    return {
      company: null,
      feedJobs: [],
      headers: [],
      makes: [],
      generalList: [],
      selectedToExport: [],
      subprices: [],
      exportList: [],
      exportHeaders: [],
      comboHours: {},
      disabledSelect: false,
      deleteFeedModal: {
        shown: false,
        id: null
      },
      images: {
        frame: false,
        zoom: false
      },
      windows: {
        scheduleModal: false,
      },
      scheduleAction: '',
      scheduleType: [
        {text: this.$t('daily'), value: 'daily'},
        {text: this.$t('weekly'), value: 'weekly'},
        {text: this.$t('monthly'), value: 'monthly'}
      ],
      scheduleDestination: [
        {text: this.$t('custom'), value: 'Default'},
        {text: 'Facebook Feed', value: 'Facebook'},
        {text: 'Google Merchant', value: 'Adwords'},
      ],
      statusOptions: [
        {text: this.$t('active'), value: 'ACTIVE'},
        {text: this.$t('in_progress'), value: 'IN-PROGRESS'},
        {text: this.$t('wholesale'), value: 'WHOLESALE'},
        {text: this.$t('inactive'), value: 'INACTIVE'},
      ],
      conditionOptions: [
        {text: 'NEW', value: 'NEW'},
        {text: 'USED', value: 'USED'},
        {text: 'USED-CPD-ELIGIBLE', value: 'USED-CPD-ELIGIBLE'},
        {text: 'CERTIFIED-PRE-OWNED', value: 'CERTIFIED-PRE-OWNED'},
        {text: 'DEMO', value: 'DEMO'},
        {text: 'DEMO-CPD-ELIGIBLE', value: 'DEMO-CPD-ELIGIBLE'},
      ],
      schedule: {
        _id: null,
        name: '',
        fileName: '',
        description: '',
        destination: 'Default',
        type: 'daily',
        protocol: 'ftp',
        host: '',
        port: 21,
        user: '',
        password: '',
        value: '',
        carstatus: [],
        make: '',
        PriceDiscount: 0
      },
      times: times,
      days: [
        {text: 'Sunday', value: 0},
        {text: 'Monday', value: 1},
        {text: 'Tuesday', value: 2},
        {text: 'Wednesday', value: 3},
        {text: 'Thursday', value: 4},
        {text: 'Friday', value: 5},
        {text: 'Saturday', value: 6}
      ],
      dailyTime: 1,
      errors: {},
      checkAll: false,
      socialEnum: {
        FACE_FEED: 'FACE_FEED',
        FACE_SHOP: 'FACE_SHOP',
        GOOGLE_MERCHANT: 'GOOGLE_MERCHANT'
      },
      valuesEditSelected: [],

      currentPage: 1,
      perPage: 25,
      totalRows: 0
    }
  },

  validations: Validations,

  computed: {
    getArrayOfTimes() {
      let array = [];
      for (let i = 1; i <= this.dailyTime; i++)
        array.push(i);
      return array;
    },

    getTextCheckButton() {
      return !this.checkAll ? this.$t('check_all') : this.$t('uncheck_all');
    },

    ...Errors
  },

  async mounted() {
    this.company = this.$getUser().Company._id;

    await Promise.all([
      this.listJobs(),
      this.InitHeaders(),
      this.getMake(),
    ]);
  },

  methods: {
    showSelectedField(selectedFields) {
      let addField = undefined;

      for (const selectedField of selectedFields) {
        const exportIndex = this.exportList.findIndex(el => el.key === selectedField);

        if (exportIndex === -1) {
          const generalField = this.generalList.find(el => el.key === selectedField);

          if (generalField) {
            generalField[selectedField] = true;
            addField = generalField;
          }
        }
      }

      if (addField) {
        this.exportList.push(addField);
      } else {
        this.exportList = this.exportList.filter(el => selectedFields.includes(el.key));
      }
    },

    getMakeField(value) {
      return value && value.length ? value : this.$t('all');
    },

    async listJobs(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      this.feedJobs = [];

      const res = await feedJobService.list(this.perPage, skip);
      if (res && !res.error) {
        this.feedJobs = res.data.feedJobs;
        this.totalRows = res.data.count;
      }
    },

    async onLaunchScheduleClick(id) {
      const res = await feedJobService.launch(id);
      if (res && !res.error) {
        this.$notify({
          type: 'success',
          message: 'Schedule is running...'
        });
      }
    },

    async onUploadToServerClick(id) {
      this.$hideModals();
      const res = await feedJobService.uploadToServer(id);
      if (res && !res.error) {
        this.$notify({type: 'success', message: 'Feed was copied to server...'});
      }
    },

    close() {
      this.$emit('close');
    },

    async onEditSchedule(id) {
      this.scheduleAction = 'edit';

      const res = await feedJobService.get(id);
      if (res && !res.error) {
        const config = res.data.feedJob;

        this.schedule._id = config._id;
        this.schedule.user = config.User;
        this.schedule.protocol = config.Protocol;
        this.schedule.host = config.Host;
        this.schedule.port = config.Port;
        this.schedule.name = config.Name;
        this.schedule.make = config.Make;
        this.schedule.carstatus = config.CarStatus;
        this.schedule.password = config.Password;
        this.schedule.fileName = config.FileName;
        this.schedule.description = config.Description;
        this.schedule.destination = config.FeedType;
        this.schedule.PriceDiscount = config.PriceDiscount;
        this.disabledSelect = config.FeedType !== 'Default';
        this.exportList = config.Headers;

        if (config.ImagesConfig) {
          this.images = {frame: config.ImagesConfig.frame, zoom: config.ImagesConfig.zoom}
        }

        for (let item of this.exportList) {
          this.exportList[this.exportList.findIndex(el => el.key === item.key)].selected = true;
          this.exportList[this.exportList.findIndex(el => el.key === item.key)].value = item.value;
        }

        for (let i = 0; i < config.Schedule.split(',').length; i++) {
          this.comboHours[i + 1] = config.Schedule.split(',')[i];
        }

        this.dailyTime = config.Schedule.split(',').length;
        this.windows.scheduleModal = true;

        if (this.exportList.length) {
          this.exportList.forEach(ele => {
            config.FeedType !== 'Default' ? this.valuesEditSelected.push(ele.value) : this.valuesEditSelected.push(ele.key)
          });
        }
      }
    },

    async onRemoveSchedule(id) {

      const res = await feedJobService.delete(id);
      if (res && !res.error) {
        this.$notify({type: 'success', message: 'Schedule was deleted...'});
        this.listJobs();
        this.hideDeleteFeedModal();

      } else {
        this.$notify({type: 'failure', message: 'Schedule was deleted...'});
        this.hideDeleteFeedModal();
      }
    },

    async onNewJobClick() {
      this.scheduleAction = 'add';
      this.windows.scheduleModal = true;
    },

    resetScheduleFields() {
      this.windows.scheduleModal = false;
      this.dailyTime = 1;
      this.schedule = {
        _id: null,
        name: '',
        fileName: '',
        description: '',
        type: 'daily',
        protocol: 'ftp',
        host: '',
        port: 21,
        destination: 'Default',
        user: '',
        password: '',
        value: '',
        carstatus: []
      };
      this.InitHeaders();
      this.exportList = [];
      this.selectedToExport = [];
      this.valuesEditSelected = [];
      this.errors = {};
      this.comboHours = {};
      this.checkAll = false;
      this.disabledSelect = false;
    },

    async onSaveScheduleClick() {
      await this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (this.schedule.type === 'daily') {
        let list = Object.values(this.comboHours);
        list = [...new Set(list.filter(el => el !== '' && el !== "-1").map(el => parseInt(el)))];

        let tmp = 0;
        for (let i = 0; i < list.length - 1; i++) {
          for (let j = i + 1; j < list.length; j++)
            if (list[i] > list[j]) {
              tmp = list[i];
              list[i] = list[j];
              list[j] = tmp;
            }
        }

        if (list.length > 0) {
          this.schedule.value = list.join(',');
        }

      } else if (this.schedule.type === 'weekly') {
        this.schedule.value = '';

      } else if (this.schedule.type === 'monthly') {
        this.schedule.value = '';

      }

      await this.SaveSchedule();
    },

    onCheckHeader(node) {
      const index = this.exportList.findIndex(el => el.key === node.key);
      if (index !== -1)
        this.exportList.splice(index, 1);
      const index1 = this.selectedToExport.findIndex(el => el === node.key);
      if (index1 !== -1)
        this.selectedToExport.splice(index1, 1);
    },

    async SaveSchedule() {
      const record = {
        _id: this.schedule._id,
        Company: this.company,
        Name: this.schedule.name,
        Description: this.schedule.description,

        ScheduleType: this.schedule.type,
        Schedule: this.schedule.value,

        Protocol: this.schedule.protocol,
        Host: this.schedule.host,
        Port: this.schedule.port,
        User: this.schedule.user,
        Password: this.schedule.password,

        FileName: this.schedule.fileName,
        FeedType: this.schedule.destination,
        CarStatus: this.schedule.carstatus,
        Headers: this.exportList,
        Make: this.schedule.make,

        ImagesConfig: this.images,
        PriceDiscount: this.schedule.PriceDiscount,
      };

      const res = await feedJobService.create(record);
      if (res && !res.error) {
        this.$notify({type: 'success', message: 'Configuration saved successfully'});
        this.resetScheduleFields();
        await this.listJobs();
      }
    },

    async InitHeaders() {
      this.generalList = [];
      this.subprices = [];

      const res = await inventoryService.getTenCars(this.company);
      if (res && !res.error) {
        this.headers = res.data;

        if (this.headers.length > 0) {
          for (let key of this.getFileHeaders()) {
            if (!key.includes('Monthly') && !key.includes('DownPayment')) {
              if (key === 'SelectedPrice') {
                continue;
              }

              this.generalList.push({key: key, value: key, selected: false});

            } else {
              this.subprices.push({key: key, value: key, selected: false});
            }
          }
        }

        this.addCustomHeaders();
      }
    },

    addCustomHeaders() {
      function detectAndRemoveDuplicates(array) {
        const keys = {};

        return array.filter(function (obj) {
          const key = obj.key;

          if (!keys[key]) {
            keys[key] = true;
            return true;
          }

          return false;
        });
      }

      const headers = [...this.generalList];

      headers.unshift(
          {
            key: 'DealerName',
            value: 'Dealer Name',
            selected: false
          }, {
            key: 'FactoryCertified',
            value: 'Factory Certified',
            selected: false
          }, {
            key: 'DealerCertified',
            value: 'Dealer Certified',
            selected: false
          }, {
            key: 'ChromeStyleID',
            value: 'Chrome Style ID',
            selected: false
          }, {
            key: 'InteriorMaterial',
            value: 'Interior Material',
            selected: false
          }, {
            key: 'Wheelbase',
            value: 'Wheelbase',
            selected: false
          }, {
            key: 'Number of Images',
            value: 'number_of_images',
            selected: false
          }, {
            key: 'VdpLink',
            value: 'VdpLink',
            selected: false
          }, {
            key: 'Google Product Category',
            value: 'google_product_category',
            selected: false
          }, {
            key: 'Vehicle Fulfillment',
            value: 'vehicle_fulfillment',
            selected: false
          }
      );

      this.generalList = detectAndRemoveDuplicates(headers);
    },

    getSubprices(price) {
      let aux = [];
      if (price === 'Price') {
        aux.push({key: 'MonthlyPrice', value: 'MonthlyPrice', selected: false});
        aux.push({key: 'DownPaymentPrice', value: 'DownPaymentPrice', selected: false});
      } else {
        for (const sub of this.subprices) {
          if (sub.key.includes(price) && sub.key.includes('Monthly')) {
            aux.push({key: sub.key, value: sub.key, selected: false});
          } else if (sub.key.includes(price) && sub.key.includes('DownPayment')) {
            aux.push({key: sub.key, value: sub.key, selected: false});
          }
        }
      }
      return aux;
    },

    getFileHeaders() {
      if (this.headers.length > 0)
        return Object.keys(this.headers[0]);
    },

    onCheckAllClick() {
      this.checkAll = !this.checkAll;
      for (let i = 0; i < this.generalList.length; i++) {
        this.generalList[i].selected = this.checkAll;
      }
      if (this.checkAll)
        this.exportList = Object.assign([], this.generalList);
      else
        this.exportList = [];
    },

    async getMake() {
      let res = await inventoryService.getMakes();
      if (res && !res.error) {
        for (const make of res.data) {
          this.makes.push({text: make.Make, value: make.Make});
        }
      }
    },

    showDeleteFeedModal(id) {
      this.deleteFeedModal = {
        shown: true,
        id: id
      };
    },

    hideDeleteFeedModal() {
      this.deleteFeedModal = {
        shown: false,
        id: null
      };
    },

    setFields(fields) {
      for (const [key, value] of Object.entries(fields)) {
        const index = this.exportList.findIndex(ele => ele.key === key);
        if (index < 0)
          this.exportList.push({
            key: key,
            value: value,
            selected: true
          });
      }
    },

    setSocialFields(shop) {
      let fields = {};

      switch (shop) {
        case this.socialEnum.GOOGLE_MERCHANT:
          fields = {
            'store_code': 'store_code',
            'price': 'Price',
            'description': 'description',
            'availability': 'availability',
            'brand': 'brand',
            'MPN': 'MPN',
            'condition': 'condition',
            'google_product_category': 'google_product_category',
            'color': 'color',
            'exterior_color': 'exterior_color',
            'trim': 'trim'
          };

          this.setFields(fields);

          break;
        case this.socialEnum.FACE_FEED:
          fields = {
            'description': 'description',
            'mileage.value': 'mileage.value',
            'transmission': 'transmission',
            'body_style': 'body_style',
            'drivetrain': 'drivetrain',
            'exterior_color': 'exterior_color',
            'interior_color': 'interior_color',
            'state_of_vehicle': 'state_of_vehicle',
            'fuel_type': 'fuel_type',
            'condition': 'condition',
            'sale_price': 'sale_price',
            'availability': 'availability',
            'trim': 'trim',
            'stock_number': 'stock_number'
          };

          this.setFields(fields);

          break
        default:
          for (const header of this.generalList) {
            if (fields[header.value.toLowerCase()]) {
              header.selected = true;
              this.exportList.push(header);
              this.selectedToExport.push(header.value);
            }
          }

          break;
      }
    },

    resetCarFields() {
      this.generalList.forEach(ele => {
        if (ele.selected)
          ele.selected = false;
      })
    },

    onChangeProtocol(protocol) {
      const protocolPortMap = {sftp: 22, ftps: 990, ftp: 21};
      this.schedule.port = protocolPortMap[protocol];
    },

    handlerChangeSchedule(el) {
      this.exportList = [];

      this.resetCarFields();

      switch (el) {
        case 'Facebook':
          this.disabledSelect = true;
          this.setSocialFields(this.socialEnum.FACE_FEED);
          break;

        case 'FacebookShop':
          this.disabledSelect = true;
          this.setSocialFields(this.socialEnum.FACE_SHOP);
          break;

        case 'Adwords':
          this.disabledSelect = true;
          this.setSocialFields(this.socialEnum.GOOGLE_MERCHANT);
          break;

        default:
          this.disabledSelect = false;
          this.selectedToExport = [];
          break;
      }
    },

    tooltipDate(value) {
      if (value) return Dh.customFormat(value, 'lll');
      return 'N/A';
    },

    async setPerPage(num) {
      this.perPage = +(num);
      this.$hideModals();
      await this.listJobs();
    },

    async next() {
      await this.listJobs(this.currentPage + 1);
    },

    async prev() {
      await this.listJobs(this.currentPage - 1);
    },
  },

  watch: {
    dailyTime: function (el, old) {
      if (el !== old && old !== 1)
        this.comboHours = {};
    },
  },

  filters: {
    formatDate(value) {
      if (value) return Dh.formatDate(value);
      return 'N/A';
    },

    formatLastRun(value) {
      if (value) {
        const {Status, Date} = value;
        return `${Dh.formatDate(Date)} (${Status})`;
      } else return 'N/A';
    },

    formatScheduledHours(values) {
      const hours = values.split(',');

      if (hours.length > 0) {

        return hours.map(hour => {
          return times.find(time => time.value === parseInt(hour, 10)).text;
        }).join(', ');

      } else return 'N/A';
    }
  },
}