import Draggable from 'vuedraggable';
import {mapActions} from 'vuex';

import dh from '@/helpers/date.helper';
import ChosenMultiple from '@/components/UI/Choosen';

import InventoryService from '@/services/inventory.service.js';
import LogService from '@/services/log.service.js';

const inventoryService = new InventoryService();
const logService = new LogService();

export default {
  name: 'export-inventory',

  components: {Draggable, ChosenMultiple},

  data() {
    return {
      filter: [
        {text: 'Custom', value: 'Default'},
        {text: 'Facebook Feed', value: 'Facebook'},
        {text: 'Google Merchant', value: 'Adwords'},
      ],
      images: {
        frame: false,
        zoom: false
      },
      socialEnum: {
        FACE_FEED: 'FACE_FEED',
        FACE_SHOP: 'FACE_SHOP',
        GOOGLE_MERCHANT: 'GOOGLE_MERCHANT'
      },
      windows: {
        selectCompany: true,
        previewData: false,
        createdModal: false,
        fileModal: false,
        selectExtension: false,
        scheduleModal: false,
      },
      generalList: [],
      subprices: [],
      exportList: [],
      selectedToExport: [],
      changeSelects: false,
      exportHeaders: [],
      disabledSelect: false,
      valuesEditSelected: [],
      exportType: 'car',
      feedType: 'Default',
      headers: {},
      extension: 'csv',
      showError: '',
      checkAll: false,
      exportedData: [],
      downloading: false,
      perPage: 10,
      totalRows: 0,
      currentPage: 1,
    }
  },

  computed: {
    getTextCheckButton() {
      return !this.checkAll ? "check_all" : "uncheck_all";
    },

    isNoData() {
      return this.generalList.length === 0;
    }
  },

  filters: {
    formatType(value) {
      return value.replace('Export', '');
    },

    formatDate(date) {
      return dh.prettyDate(date);
    },
  },

  mounted() {
    let Company = this.$getUser().Company;
    if (Company !== undefined)
      this.company = Company._id;

    this.loadPreviewCars();
    this.exportedList();
  },

  methods: {
    ...mapActions([
      'setDownloading'
    ]),

    changeSelected() {
      this.changeSelects = !this.changeSelects;
    },

    showSelectedField(data) {
      let aux = [];
      for (const o of this.generalList) {
        for (const i of data) {
          if (o.key.toLowerCase() === i.toLowerCase()) {
            const newVal = o;
            newVal.selected = !newVal.selected;
            aux.push(newVal);
          }
        }
      }
      this.exportList = aux;
    },

    async exportedList(page = 1) {
      this.currentPage = page;
      const skip = this.perPage * (this.currentPage - 1);

      const res = await logService.listExportImportInventory(this.perPage, skip);
      if (res && !res.error) {
        this.exportedData = res.data.records;
        this.totalRows = res.data.count;
      }
    },

    async loadPreviewCars() {
      let res = await inventoryService.getTenCars(this.company);
      if (res && !res.error) {
        this.list = res.data;
        if (this.list.length > 0) {
          for (let key of this.getFileHeaders()) {
            if (!key.includes('Monthly') && !key.includes('DownPayment')) {
              if (key === 'SelectedPrice')
                continue;
              this.generalList.push({key: key, value: key, selected: false});
            } else {
              this.subprices.push({key: key, value: key, selected: false});
            }
          }

          this.generalList.push({
            key: 'Number of Images',
            value: 'number_of_images',
            selected: false
          });

          this.generalList.push({
            key: 'VdpLink',
            value: 'VdpLink',
            selected: false
          });
        }

      }
    },

    getSubprices(price) {
      let aux = [];
      if (price === 'Price') {
        aux.push({key: 'MonthlyPrice', value: 'MonthlyPrice', selected: false});
        aux.push({key: 'DownPaymentPrice', value: 'DownPaymentPrice', selected: false});
      } else {
        for (const sub of this.subprices) {
          if (sub.key.includes(price) && sub.key.includes('Monthly')) {
            aux.push({key: sub.key, value: sub.key, selected: false});
          } else if (sub.key.includes(price) && sub.key.includes('DownPayment')) {
            aux.push({key: sub.key, value: sub.key, selected: false});
          }
        }
      }
      return aux;
    },

    onCheckHeader(node) {
      if (node.selected === true)
        this.exportList.push(node);
      else {
        const index = this.exportList.findIndex(el => el.key === node.key);
        if (index !== -1)
          this.exportList.splice(index, 1);
      }
    },

    onCheckAllClick() {
      this.checkAll = !this.checkAll;
      for (let i = 0; i < this.generalList.length; i++) {
        this.generalList[i].selected = this.checkAll;
      }
      if (this.checkAll)
        this.exportList = Object.assign([], this.generalList);
      else
        this.exportList = [];
    },

    onNextClick() {
      if (!this.windows.createdModal) {
        this.feedType === 'Default';
        this.windows.createdModal = true;
      } else {
        if (this.exportList.length === 0)
          this.$notify({
            type: 'error',
            message: 'You have not fields selected.'
          });
        else {
          this.headers = this.exportList;
          this.windows.fileModal = true;
        }
      }

    },

    convertHeadersInFieldsParser() {
      let array = [];
      for (let item of this.headers) {
        array.push({label: item.value, value: item.key})
      }
      return array;
    },

    async Export() {
      this.downloading = true;
      this.setDownloading(true);
      if (this.feedType !== 'Default') {
        this.BuildFeed(this.feedType);
        return;
      }
      const fields = this.convertHeadersInFieldsParser();
      let res = await inventoryService.Export(fields, this.company, this.extension, this.images);
      this.setDownloading(false);
      if (res && !res.error) {
        const byte = this.base64ToArrayBuffer(res.data.array);
        let blob = new Blob([byte], {type: "application/octet-binary;charset=utf-8"});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${res.data.name}.${this.extension}`);
        document.body.appendChild(link);
        link.click();
        this.downloading = false;
        this.exportedList();
        this.cancelFileModal();
        this.cancelExportModal();
        this.feedType = 'Default';
        this.exportList = [];
      } else {
        this.downloading = false;
        this.cancelFileModal();
        this.cancelExportModal();
        this.feedType = 'Default';
        this.exportList = [];
      }

    },

    async BuildFeed(type) {

      let res = null;
      switch (type) {
        case "Facebook":
          res = await inventoryService.GetFacebookFeed(this.company, this.headers, this.images);
          break;
        case "FacebookShop":
          res = await inventoryService.GetFacebookShopFeed(this.company, this.headers, this.images);
          break;
        case "Adwords":
          res = await inventoryService.GetAdWordsFeed(this.company, this.headers, this.images);
          break;
      }

      if (res && !res.error) {
        const byte = this.base64ToArrayBuffer(res.data.array);
        let blob = new Blob([byte], {type: "application/octet-binary;charset=utf-8"});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `${res.data.name}.csv`);
        document.body.appendChild(link);
        link.click();

        this.exportedList();
        this.cancelFileModal();
        this.cancelExportModal();
        this.feedType = 'Default';
        this.exportList = [];
        this.downloading = false;

      } else {
        this.exportedList();
        this.cancelFileModal();
        this.cancelExportModal();
        this.feedType = 'Default';
        this.exportList = [];
        this.downloading = false;
      }

    },

    showExportModal(headers) {
      this.headers = headers;
      this.windows.createdModal = true;
    },

    cancelExportModal() {
      this.feedType = 'Default';
      this.disabledSelect = false;
      this.valuesEditSelected = [];
      this.exportList = [];
      this.windows.createdModal = false;
      this.checkAll = true;
      this.onCheckAllClick();
    },

    cancelFileModal() {
      this.windows.fileModal = false;
    },

    getFileHeaders() {
      if (this.list.length > 0)
        return Object.keys(this.list[0]);
    },

    onClose() {
      this.$router.push({name: 'DataExport'})
    },

    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes;
    },

    validateExport() {
      if (this.feedType === "Default" && this.exportList.length === 0) {
        return true;
      }
      return false;
    },

    next() {
      this.exportedList(this.currentPage + 1);
    },

    prev() {
      this.exportedList(this.currentPage - 1);
    },

    setFields(fields) {
      for (const [key, value] of Object.entries(fields)) {
        const index = this.exportList.findIndex(ele => ele.key === key);
        if (index < 0)
          this.exportList.push({
            key: key,
            value: value,
            selected: true
          });
      }
    },

    setSocialFields(shop) {
      let fields = {};
      switch (shop) {
        case this.socialEnum.GOOGLE_MERCHANT:
          fields = {
            'description': 'description',
            'availability': 'availability',
            'brand': 'brand',
            'MPN': 'MPN',
            'condition': 'condition',
            'color': 'color',
          };
          this.setFields(fields);
          break;
        case this.socialEnum.FACE_FEED:
          fields = {
            'description': 'description',
            'mileage.value': 'mileage.value',
            'transmission': 'transmission',
            'body_style': 'body_style',
            'drivetrain': 'drivetrain',
            'exterior_color': 'exterior_color',
            'interior_color': 'interior_color',
            'state_of_vehicle': 'state_of_vehicle',
            'fuel_type': 'fuel_type',
            'condition': 'condition',
            'sale_price': 'sale_price',
            'availability': 'availability',
            'trim': 'trim',
            'stock_number': 'stock_number'
          };
          this.setFields(fields);
          break
        default:
          for (const header of this.generalList) {
            if (fields[header.value.toLowerCase()]) {
              header.selected = true;
              this.exportList.push(header);
              this.selectedToExport.push(header.value);
            }
          }
          break;
      }
    },

    resetCarFields() {
      this.generalList.forEach(ele => {
        if (ele.selected)
          ele.selected = false;
      })
    },

    handlerChangeSchedule(el) {
      this.exportList = [];
      this.resetCarFields();
      switch (el) {
        case 'Facebook':
          this.changeSelected();
          this.disabledSelect = true;
          this.setSocialFields(this.socialEnum.FACE_FEED);
          break;
        case 'FacebookShop':
          this.changeSelected();
          this.disabledSelect = true;
          this.setSocialFields(this.socialEnum.FACE_SHOP);
          break;
        case 'Adwords':
          this.changeSelected();
          this.disabledSelect = true;
          this.setSocialFields(this.socialEnum.GOOGLE_MERCHANT);
          break;
        default:
          this.disabledSelect = false;
          this.selectedToExport = [];
          break;
      }
    }
  }
}