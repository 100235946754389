import CompanyService from '@/services/company.service.js';
import LogService from '@/services/log.service.js';
import DateHelper from '@/helpers/date.helper';

const companyService = new CompanyService();
const logService = new LogService();

export default {
    name: 'list',
    components: {},
    props: [],
    data() {
        return {
            companyList: [],
            records: [],

            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            searchVal: ''
        }
    },
    computed: {},
    mounted() {
        if(this.$route.params.name) {
            this.searchVal = `type=${this.$route.params.name}`;
            this.list();
        }
    },
    filters: {
        formatDate(date) {
            return DateHelper.prettyFullDate(date);
        }
    },
    methods: {
        async list(page) {
            this.currentPage = page || 1;
            const skip = this.perPage * (this.currentPage - 1);

            const res = await logService.listimportexport(this.perPage, skip, this.searchVal);
            if (res && !res.error) {
                this.records = res.data.records;
                this.totalRows = res.data.count;
            }
                
        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.list();
        },
        
        async getCompanies() {

            const res = await companyService.list();
            if (res && !res.error)
                this.companyList = res.data.companies;
        },

        async onExportFeedClick(company) {
            this.company = company;
            const res = await companyService.get(this.company);
            if (res && !res.error) {
                const config = res.data.CompanySettings.Feed.FtpUpload;
                this.schedule.user = config.user;
                this.schedule.password = config.password;
                this.schedule.port = config.port;
                this.schedule.host = config.host;
                this.schedule.name = !config.name || config.name == '' ? `${res.data.Name}-Feed` : config.name;
                this.headers = config.headers;
            }
            this.windows.scheduleModal = true;
        },

        async onFeedClick(company) {

            this.company = company;
            this.exportType = 'car';
            this.windows.previewData = true;
            this.windows.selectCompany = false;
        },

        async onLeadClick(company) {
            this.company = company;
            this.exportType = 'lead';
            this.windows.previewData = true;
            this.windows.selectCompany = false;
        },


        onConfigFeedClick(company) {
            this.$emit('ConfigExportFeedEvent', company);
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },
    }
}
