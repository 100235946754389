import { render, staticRenderFns } from "./SetData.html?vue&type=template&id=271c7a36&scoped=true&external"
import script from "./SetData.js?vue&type=script&lang=js&external"
export * from "./SetData.js?vue&type=script&lang=js&external"
import style0 from "./SetData.scss?vue&type=style&index=0&id=271c7a36&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271c7a36",
  null
  
)

export default component.exports