import AgentService from '@/services/agent.service';

const service = new AgentService();

export default {
  name: 'AgentFilter',

  props: {
    selected: {
      type: String,
      default: ''
    },
    roles: {
      type: Array,
      default: () => []
    },
  },

  data() {
    return {
      agents: [],
      agent: null,
      search: ''
    }
  },

  created() {
    this.initializeAgentFromQuery();
  },

  async mounted() {
    await this.list();

    if (this.$route.query.agent) {
      this.setAgentFromQuery();

    } else if (this.selected) {
      this.setSelectedAgent();
    }
  },

  computed: {
    agentss() {
      if (this.search) {
        return this.agents.filter(agent => {
          return this.search
              .toLowerCase()
              .split(' ')
              .every(search => {
                return `${agent.User.FirstName} ${agent.User.LastName}`
                    .toLowerCase().includes(search);
              });
        });
      }

      return this.agents
    }
  },

  methods: {
    async toggleAgent(agent) {
      await this.setRouteQuery(agent);

      this.agent = agent;
      this.$emit('select', agent ? agent.User._id : '');
      this.$hideModals();
      this.search = '';
    },

    async list() {
      if (this.roles.length) {
        const res = await service.listByRole(this.roles.join(','), 100);
        if (res && !res.error) {
          this.agents = res.data.agents;
        }

      } else {
        const res = await service.list(100);
        if (res && !res.error) {
          this.agents = res.data.agents;
        }
      }
    },

    setSelectedAgent() {
      if (this.selected?.length) {
        const index = this.agents.findIndex(agent => agent.User._id === this.selected);
        if (index > -1) {
          this.agent = this.agents[index];
        }

      } else if (this.$route.query.agent?.length) {
        const index = this.agents.findIndex(agent => agent.User._id === this.$route.query.agent);
        if (index > -1) {
          this.agent = this.agents[index];
        }
      }
    },

    async setRouteQuery(agent) {
      if (agent) {
        const isValidAgentQuery = agent?._id !== this.agent?._id;
        if (isValidAgentQuery) {
          await this.$router.replace({
            path: this.$route.path,
            query: {...this.$route.query, agent: agent.User?._id || agent.User},
          });
        }

      } else {
        const currentQuery = {...this.$route.query};
        if (currentQuery.agent) {
          delete currentQuery.agent;
          await this.$router.replace({path: this.$route.path, query: {...currentQuery}});
        }
      }
    },

    setAgentFromQuery() {
      const queryAgent = this.$route.query.agent;

      if (queryAgent) {
        const index = this.agents.findIndex(agent => agent.User._id === queryAgent);
        if (index > -1) {
          this.agent = this.agents[index];
        }
      }
    },

    initializeAgentFromQuery() {
      const agentFromQuery = this.$route.query.agent;

      if (agentFromQuery) {
        this.$emit('init', agentFromQuery);

      } else if (this.selected) {
        this.$emit('init', this.selected);
      }
    }
  }
}